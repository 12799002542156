import { inject, Injectable } from '@angular/core';
import { Content, filter } from '@prismicio/client';
import { LanguageService } from 'language';
import { CachedDataLoaderService } from 'utils';
import { ChannelService } from './channel.service';
import { CmsService } from './cms.service';
import { PrismicProduct } from './interfaces/prismic-product.class';
import { TableDataProducts, TableHomeInternet, TableMobile, TableTv } from './interfaces/product-box-table.class';

export const convertProductBoxTable = (slice: Content.ProductBoxDocumentDataBodySlice) => {
  if (slice.slice_type === 'table_tv') {
    return new TableTv(slice);
  }

  if (slice.slice_type === 'table_home_internet') {
    return new TableHomeInternet(slice);
  }

  if (slice.slice_type === 'table_data_products') {
    return new TableDataProducts(slice);
  }

  if (slice.slice_type === 'table_mobile') {
    return new TableMobile(slice);
  }
};

@Injectable({
  providedIn: 'root',
})
export class PrismicLibService {
  private cms = inject(CmsService);
  private dataLoader = inject(CachedDataLoaderService);
  private languageService = inject(LanguageService);
  private channels = inject(ChannelService);

  public async getProducts(channel = 'ol'): Promise<PrismicProduct[]> {
    return this.dataLoader.get(this.getStateKey(channel), () => this.loadProducts(channel));
  }

  public async getProduct(productCode: string, channel = 'ol'): Promise<PrismicProduct> {
    return this.dataLoader.get(this.getStateKey(productCode + '_' + channel), () =>
      this.loadProduct(productCode, channel)
    );
  }

  public async getProductBoxes() {
    return await this.cms.getByType('product_box');
  }

  private async loadProducts(channelCode?: string) {
    let channel;
    let productBoxesFromPrismic;
    if (!channelCode) {
      productBoxesFromPrismic = await this.cms.getByType('product_box');
    } else {
      channel = await this.channels.findChannel(channelCode);
      productBoxesFromPrismic = await this.cms.getByContentRelationship('product_box', 'channels.channel', channel.id);
    }

    const products: PrismicProduct[] = [];

    for (const doc of productBoxesFromPrismic.filter(item => item.data.product_name)) {
      const channelCode = channel ? channel.id : undefined;
      products.push(
        PrismicProduct.fromPrismic(doc.id, doc.data as unknown as Content.ProductBoxDocumentData, channelCode)
      );
    }

    return products;
  }

  private async loadProduct(productCode: string, channelCode: string) {
    if (!productCode) return null;
    const productBoxesFromPrismic = await this.cms.getByType('product_box', [
      filter.at('my.product_box.product_code', productCode),
    ]);

    return PrismicProduct.fromPrismic(
      productBoxesFromPrismic[0].id,
      productBoxesFromPrismic[0].data as unknown as Content.ProductBoxDocumentData,
      channelCode
    );
  }

  private getStateKey(channel?: string) {
    return 'products_' + channel + '_' + this.languageService.current;
  }
}
