<ng-template #linkContent>
  @if (hasPrefixIcon) {
    <lib-svg [svg]="icon" class="inline-block align-middle"></lib-svg>
  }
  <span [class]="textCssClass">
    <!-- Workaround for strange issue with content projection for modal links in landing page partial, when running SSR -->
    <span></span>
    <ng-content></ng-content>
  </span>
  @if (hasSuffixIcon) {
    <lib-svg [svg]="icon" class="inline-block align-middle"></lib-svg>
  }
</ng-template>

@if (isAnchorTagLink()) {
  <a
    href=""
    (click)="scrollTo($event, url())"
    [class]="cssClass"
    [target]="target"
    [title]="title"
    [attr.aria-label]="ariaLabel"
    class="max-w-full"
  >
    <ng-container [ngTemplateOutlet]="linkContent"></ng-container>
  </a>
} @else {
  @if (isValidLink) {
    @if (link().link_type === LinkType.Media || link().link_type === LinkType.Web) {
      <a
        [attr.href]="url()"
        [class]="cssClass"
        [target]="target"
        [title]="title"
        [attr.aria-label]="ariaLabel"
        (click)="onMediaOrWebLinkClick()"
        class="max-w-full"
      >
        <ng-container [ngTemplateOutlet]="linkContent"></ng-container>
      </a>
    }
    @if (link().link_type === LinkType.Document) {
      @if (documentType === 'modal_kitsune') {
        <a [class]="cssClass" href="javascript:void(0)" (click)="onDocumentLinkClick()" class="max-w-full">
          <ng-container [ngTemplateOutlet]="linkContent"></ng-container>
        </a>
      } @else {
        <a
          [routerLink]="url()"
          [queryParams]="queryParams"
          [class]="cssClass"
          [title]="title"
          [queryParamsHandling]="queryParamsHandling"
          [attr.aria-label]="ariaLabel"
          (click)="onDocumentLinkClick()"
          class="max-w-full"
        >
          <ng-container [ngTemplateOutlet]="linkContent"></ng-container>
        </a>
      }
    }
  }
}
