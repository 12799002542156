import { LinkType } from "../types/value/link.js";
const documentToLinkField = prismicDocument => {
  var _a;
  return {
    link_type: LinkType.Document,
    id: prismicDocument.id,
    uid: prismicDocument.uid || void 0,
    type: prismicDocument.type,
    tags: prismicDocument.tags,
    lang: prismicDocument.lang,
    url: prismicDocument.url == null ? void 0 : prismicDocument.url,
    slug: (_a = prismicDocument.slugs) == null ? void 0 : _a[0],
    // Slug field is not available with GraphQL
    // The REST API does not include a `data` property if the data
    // object is empty.
    //
    // A presence check for `prismicDocument.data` is done to
    // support partial documents. While `documentToLinkField` is
    // not typed to accept partial documents, passing a partial
    // document can happen in untyped projects.
    ...(prismicDocument.data && Object.keys(prismicDocument.data).length > 0 ? {
      data: prismicDocument.data
    } : {})
  };
};
export { documentToLinkField };
