import { RichTextReversedNodeType } from "./types.js";
const wrapMapSerializer = mapSerializer => {
  return (type, node, text, children, key) => {
    const tagSerializer = mapSerializer[RichTextReversedNodeType[type] || type];
    if (tagSerializer) {
      return tagSerializer({
        // @ts-expect-error cannot type check here
        type,
        // @ts-expect-error cannot type check here
        node,
        // @ts-expect-error cannot type check here
        text,
        // @ts-expect-error cannot type check here
        children,
        // @ts-expect-error cannot type check here
        key
      });
    }
  };
};
export { wrapMapSerializer };
