import { DOCUMENT, isPlatformServer } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Params, Router, UrlSerializer } from '@angular/router';
import { Response } from 'express';
import { from, of } from 'rxjs';
import { RESPONSE } from 'utils';

@Injectable({
  providedIn: 'root',
})
export class RedirectService {
  private platformId = inject(PLATFORM_ID);
  private doc = inject<Document>(DOCUMENT);
  private response = inject<Response>(RESPONSE, { optional: true });
  private router = inject(Router);
  private serializer = inject(UrlSerializer);

  redirectTo(url: string, queryParams?: Params, replaceUrl = false) {
    if (url.startsWith('http')) {
      this.doc.defaultView.open(url, '_self');
      return of(false);
    }

    if (isPlatformServer(this.platformId)) {
      const urlTree = this.router.createUrlTree([url], { queryParams });
      // Returning a 302 when we are on the server
      this.response?.redirect(this.serializer.serialize(urlTree));
      return of(false);
    }

    return from(this.router.navigate([url], { queryParams, replaceUrl }));
  }
}
