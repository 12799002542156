import { inject, Injectable } from '@angular/core';
import { BrandConfig } from './brand-config.interface';
import { Brand } from './brand.interface';
import { BRAND_CONFIG } from './brand.token';

@Injectable({
  providedIn: 'root',
})
export class BrandService {
  private config = inject<BrandConfig>(BRAND_CONFIG);
  public brand: Brand;

  constructor() {
    this.brand = this.config.brand;
  }

  isLebara() {
    return this.brand === 'lebara';
  }

  isYallo() {
    return this.brand === 'yallo';
  }
}
