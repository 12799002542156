import { preview } from "../cookie.js";
const readValue = value => {
  return value.replace(/%3B/g, ";");
};
const getPreviewCookie = cookieJar => {
  const cookies = cookieJar.split("; ");
  let value;
  for (const cookie of cookies) {
    const parts = cookie.split("=");
    const name = readValue(parts[0]).replace(/%3D/g, "=");
    if (name === preview) {
      value = readValue(parts.slice(1).join("="));
      break;
    }
  }
  return value;
};
export { getPreviewCookie };
