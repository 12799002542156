import { SliceClass } from 'interfaces';
import { prismicToHtmlString } from '../helpers/prismic-to-html-string';
import {
  ProductBoxDocumentDataBodyTableDataProductsSlice,
  ProductBoxDocumentDataBodyTableHomeInternetSlice,
  ProductBoxDocumentDataBodyTableMobileSlice,
  ProductBoxDocumentDataBodyTableTvSlice,
} from '../types.generated.interface';

export class TableMobile extends SliceClass {
  chData: string;
  roamingData: string;
  chCalls: string;
  roamingCalls: string;

  constructor(sliceData: ProductBoxDocumentDataBodyTableMobileSlice) {
    super(sliceData);
    this.chData = prismicToHtmlString(sliceData.primary.ch_data);
    this.roamingData = prismicToHtmlString(sliceData.primary.roaming_data);
    this.chCalls = prismicToHtmlString(sliceData.primary.ch_calls);
    this.roamingCalls = prismicToHtmlString(sliceData.primary.roaming_calls);
  }
}

export class TableHomeInternet extends SliceClass {
  speed: string;
  internetBox: boolean;
  tv: string;

  constructor(sliceData: ProductBoxDocumentDataBodyTableHomeInternetSlice) {
    super(sliceData);
    this.speed = prismicToHtmlString(sliceData.primary.speed);
    this.internetBox = sliceData.primary.internet_box;
    this.tv = prismicToHtmlString(sliceData.primary.tv);
  }
}

export class TableDataProducts extends SliceClass {
  speed: string;
  data: string;

  constructor(sliceData: ProductBoxDocumentDataBodyTableDataProductsSlice) {
    super(sliceData);
    this.speed = prismicToHtmlString(sliceData.primary.ch_speed);
    this.data = prismicToHtmlString(sliceData.primary.ch_data);
  }
}

export class TableTv extends SliceClass {
  tvChannels: string;
  recording: string;
  tvBox: boolean;

  constructor(sliceData: ProductBoxDocumentDataBodyTableTvSlice) {
    super(sliceData);
    this.tvChannels = prismicToHtmlString(sliceData.primary.tv_channels);
    this.recording = prismicToHtmlString(sliceData.primary.recording);
    this.tvBox = sliceData.primary.tv_box;
  }
}
