import { inject, Injectable } from '@angular/core';
import { PrismicDocument } from '@prismicio/client';
import { LanguageService } from 'language';
import { CachedDataLoaderService } from 'utils';
import { CmsService } from './cms.service';
import { Channel } from './interfaces/channel.class';

@Injectable({
  providedIn: 'root',
})
export class ChannelService {
  private cms = inject(CmsService);
  private lang = inject(LanguageService);
  private dataLoader = inject(CachedDataLoaderService);

  async getChannels(): Promise<Channel[]> {
    return this.dataLoader.get(this.getStateKey(), () => this.loadChannels());
  }

  async findChannel(channelCode: string) {
    return (await this.getChannels()).find((c: Channel) => c.code === channelCode);
  }

  private getStateKey() {
    return 'channels_' + this.lang.current;
  }

  /**
   * Get all of the channels from prismic
   */
  private async loadChannels() {
    const resp = await this.cms.getByType('channel');
    return resp.map((c: PrismicDocument) => new Channel(c));
  }
}
