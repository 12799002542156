import { asHTML, RichTextField } from '@prismicio/client';
import { checkForAnchorTagLink } from './check-for-anchor-tag-link';

export type NodeData = {
  id?: string;
  type?: string;
  target?: string;
  uid?: string;
  link_type?: string;
  url?: string;
  slug?: string;
};

const htmlSerializer = {
  hyperlink: (params: { node: { data: NodeData }; children: unknown }) => {
    const { node, children } = params;
    if (checkForAnchorTagLink(node.data)) {
      return `<lib-link [link]="{link_type: 'Web', url: '${node.data.url}'}">${children}</lib-link>`;
    }
    if (isModalLInk(node.data)) {
      return `<lib-link [link]="{link_type: 'Document', slug: '${node.data.slug}', type: 'modal_kitsune', id: '${node.data.id}'}">${children}</lib-link>`;
    }
    if (isNewLandingPage(node.data)) {
      return `<lib-link [link]="{link_type: 'Document', slug: '${node.data.slug}', type: 'new_landing_page', id: '${node.data.id}'}">${children}</lib-link>`;
    }
    return null;
  },
};

const isModalLInk = (link: NodeData) => link.type === 'modal_kitsune';
export const isNewLandingPage = (link: NodeData) => link?.type === 'new_landing_page';

export const prismicToHtmlString = (raw: RichTextField) => {
  if (!raw) return '';
  return (
    asHTML(raw, { serializer: htmlSerializer })
      .replace(/&amp;/g, '&')
      .replace(/&gt;/g, '>')
      .replace(/&lt;/g, '<')
      .replace(/&quot;/g, '"')
      // eslint-disable-next-line no-irregular-whitespace
      .replace(/ /g, ' ')
  );
};
