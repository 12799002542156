import { ProductCatalogService } from '@yol-digital/ms-client';

export class Promotion {
  valid: boolean;
  priority: number;

  constructor(
    public id?: string,
    public code?: string,
    public activationFeeDiscount?: number,
    public discountAmount?: number,
    public discountDuration?: number,
    public title?: string,
    public lockinLengths?: number[],
    public channels?: { id: string; availability: 'Default' | 'Non default' }[]
  ) {}

  static fromMS(
    PromotionData: ProductCatalogService.CatalogResponse['defaultPromotion'],
    promoFromPrismic?: Promotion
  ): Promotion {
    const promotion = new this(
      PromotionData.id,
      PromotionData.code,
      PromotionData.activationFeeDiscount,
      PromotionData.discount,
      PromotionData.duration,
      promoFromPrismic ? promoFromPrismic.title : PromotionData.displayName,
      PromotionData.availableLockings,
      [{ id: 'ol', availability: 'Default' }]
    );

    return promotion;
  }

  static discountPercentage(base?: number, discount?: number): string | number {
    const discountedPrice = base - discount;
    if (typeof discountedPrice === 'number' && !Number.isNaN(discountedPrice)) {
      return Math.floor(((base - discountedPrice) / base) * 100);
    }
    return 0;
  }
}
