import { ImageFieldImage } from '@prismicio/client';

class ImageDimensions {
  width: number;
  height: number;

  constructor(raw: { width: number; height: number }) {
    this.width = raw?.width;
    this.height = raw?.height;
  }
}

export class Image {
  dimensions: ImageDimensions;

  constructor(
    private u: string,
    dimensions: ImageDimensions,
    public alt?: string,
    public copyright?: string
  ) {
    this.dimensions = new ImageDimensions(dimensions);
  }

  get url() {
    const u = new URL(this.u);
    u.searchParams.forEach((value, key, parent) => parent.set(key, encodeURIComponent(value)));
    return u.href;
  }

  get htmlSrcset() {
    return `${this.url} ${this.dimensions.width}w`;
  }

  get htmlSize() {
    return `(max-width: ${this.dimensions.width * 2}px) ${this.dimensions.width}px`;
  }
}

export class ImageSet {
  original: Image;
  medium?: Image;
  small?: Image;
  large?: Image;

  constructor(raw: ImageFieldImage) {
    this.original = new Image(raw.url, raw.dimensions, raw.alt, raw.copyright);
    if ('large' in raw) {
      const large = raw.large as ImageFieldImage;
      this.large = new Image(large.url, large.dimensions, large.alt, large.copyright);
    }
    if ('medium' in raw) {
      const medium = raw.medium as ImageFieldImage;
      this.medium = new Image(medium.url, medium.dimensions, medium.alt, medium.copyright);
    }
    if ('mobile' in raw) {
      const mobile = raw.mobile as ImageFieldImage;
      this.small = new Image(mobile.url, mobile.dimensions, mobile.alt, mobile.copyright);
    }
  }

  get smallest() {
    if (this.small) return this.small;
    if (this.medium) return this.medium;
    return this.original;
  }

  get htmlSrcset() {
    return [this.small?.htmlSrcset, this.medium?.htmlSrcset, this.large?.htmlSrcset, this.original?.htmlSrcset]
      .filter(v => v)
      .join(', ');
  }

  get htmlSizes() {
    return [this.small?.htmlSize, this.medium?.htmlSize, this.large?.htmlSize, `${this.original.dimensions.width}px`]
      .filter(v => v)
      .join(',');
  }

  get alt() {
    return this.original.alt ?? ' ';
  }
}
