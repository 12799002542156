var __defProp = Object.defineProperty;
var __defNormalProp = (obj, key, value) => key in obj ? __defProp(obj, key, {
  enumerable: true,
  configurable: true,
  writable: true,
  value
}) : obj[key] = value;
var __publicField = (obj, key, value) => __defNormalProp(obj, typeof key !== "symbol" ? key + "" : key, value);
class PrismicError extends Error {
  constructor(message = "An invalid API response was returned", url, response) {
    super(message);
    __publicField(this, "url");
    __publicField(this, "response");
    this.url = url;
    this.response = response;
  }
}
export { PrismicError };
