import { PrismicSlice } from './prismic.response.interface';

export class SliceClass {
  type: string;
  label: string;
  primary: unknown;
  items: unknown[];
  isHeadSlice = false;

  constructor(sliceData: PrismicSlice) {
    this.type = sliceData.slice_type;
    this.label = sliceData.slice_label;
  }
}
