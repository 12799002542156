import { PrismicDocument } from '@prismicio/client';

export class Channel {
  /** Prismic id  */
  readonly id: string;
  /** Two letter short code */
  readonly code: string;
  readonly name: string;

  constructor(prismicData: PrismicDocument) {
    this.id = prismicData.id;
    this.code = prismicData.data['channel_code'];
    this.name = prismicData.data['channel_name'];
  }
}
