import { Content, LinkField } from '@prismicio/client';
import { ProductCatalogService } from '@yol-digital/ms-client';
import { Image } from 'interfaces';
import { urlify } from 'utils';
import { prismicToHtmlString } from '../helpers/prismic-to-html-string';
import { prismicToImage } from '../helpers/prismic-to-image';
import { convertProductBoxTable } from '../prismic.service';
import { TableDataProducts, TableHomeInternet, TableMobile, TableTv } from './product-box-table.class';
import CatalogResponse = ProductCatalogService.CatalogResponse;

export class PrismicProduct {
  id: string;
  source: CatalogResponse['source'];
  name: string;
  short_description: string;
  url: string;
  monthly_cost: Content.ProductBoxDocumentData['monthly_cost'] | CatalogResponse['basePrice'];
  activation_fee?: CatalogResponse['activationFee'];
  rateplan_id: Content.ProductBoxDocumentData['rateplan_id'] | CatalogResponse['id'];
  type: Lowercase<Content.ProductBoxDocumentData['type']> | string;
  //TODO: Remove mbb and fiber when the content team migrate to product box kitsune
  product_type: Lowercase<Content.ProductBoxDocumentData['product_type']> | CatalogResponse['productSpecFamily'];
  lockin_length: number[] = []; // Note, this can be different depending on the channel
  product_icon: Image;
  product_details_link: Content.ProductBoxDocumentData['product_details_link'];
  product_details_link_label: Content.ProductBoxDocumentData['product_details_link_label'];
  cta_button_label: Content.ProductBoxDocumentData['cta_button_label'];
  alternative_link: Content.ProductBoxDocumentData['alternative_link'];
  promotion_banner_text: Content.ProductBoxDocumentData['promotion_banner_text'];
  special_product_type: Content.ProductBoxDocumentData['special_product_type'];
  product_code: Content.ProductBoxDocumentData['product_code'] | CatalogResponse['code'];
  productSpecFamily: CatalogResponse['productSpecFamily'];
  productSpecSubCategory: Content.ProductBoxDocumentData['product_spec_sub_category'];
  productSpecClass: CatalogResponse['productSpecClass'];
  defaultPromotion?: CatalogResponse['defaultPromotion'];
  prismicTable?: TableHomeInternet | TableDataProducts | TableMobile | TableTv;
  item_category?: 'Mobile abo' | 'TV' | 'Prepaid' | 'Fixline' | 'Activation fee';
  item_category2?: 'Mobile voice' | 'TV' | 'Prepaid' | 'Mobile data' | 'Internet' | 'Activation fee';
  item_category3?: 'Mobile voice' | 'TV' | 'Prepaid SIM' | 'Internet on the Go' | 'Internet at Home' | 'Activation fee';
  item_category4?: 'Activation fee' | 'not selfcare';
  item_category5?: 'Activation fee' | 'not selfcare';
  item_variant?:
    | 'postpaid mobile'
    | 'tv subscription'
    | 'prepaid mobile'
    | 'postpaid mbb'
    | 'postpaid cable'
    | 'postpaid fiber'
    | 'service fees';

  // static helper method to create Products from prismic Data
  static fromPrismic(id: string, data: Content.ProductBoxDocumentData, channelId: string): PrismicProduct {
    const p = new this();

    p.id = id;
    p.product_code = data.product_code;
    p.name = data.display_name ?? data.product_name.replace(/yallo|lebara/g, '');
    p.short_description = prismicToHtmlString(data.short_description);
    p.url = urlify(data.product_name);
    p.monthly_cost = data.monthly_cost;
    p.type = data.type?.toLowerCase();
    p.rateplan_id = data.rateplan_id;
    p.product_type = data.product_type?.toLowerCase();
    if (data.product_icon?.url) {
      p.product_icon = prismicToImage(data.product_icon);
    }

    p.product_details_link_label = data.product_details_link_label;
    if (data.product_details_link.link_type === 'Web' || data.product_details_link.link_type === 'Document') {
      p.product_details_link = data.product_details_link as LinkField;
    }

    if (data.body && data.body[0]) {
      p.prismicTable = convertProductBoxTable(data.body[0]);
    }

    //A product from prismic can have multiple channels, so only add lockinLength if there's a channelId
    if (channelId) {
      const d = data.channels.find((d: { channel: { id: string } }) => d.channel.id === channelId);
      if (d) {
        if (d['0_month_lockin']) p.lockin_length.push(0);
        if (d['12_month_lockin']) p.lockin_length.push(12);
        if (d['24_month_lockin']) p.lockin_length.push(24);
      }
    }

    p.cta_button_label = data.cta_button_label;
    p.alternative_link = data.alternative_link;
    p.promotion_banner_text = data.promotion_banner_text;
    p.special_product_type = data.special_product_type;

    return p;
  }

  discountedPrice(discount?: number): number {
    const cost = +this.monthly_cost;
    if (!cost || isNaN(cost) || !discount) {
      return this.monthly_cost;
    }

    return Number((cost - discount).toFixed(2));
  }

  protected setAnalyticsCategories(productSpecClass: string, productSpecFamily: string) {
    switch (productSpecClass?.toUpperCase() + '/' + productSpecFamily?.toUpperCase()) {
      case 'POSTPAID/MOBILE':
        this.item_category = 'Mobile abo';
        this.item_category2 = 'Mobile voice';
        this.item_category3 = 'Mobile voice';
        this.item_category4 = 'not selfcare';
        this.item_category5 = 'not selfcare';
        this.item_variant = 'postpaid mobile';
        break;
      case 'TV/TV':
        this.item_category = 'TV';
        this.item_category2 = 'TV';
        this.item_category3 = 'TV';
        this.item_category4 = 'not selfcare';
        this.item_category5 = 'not selfcare';
        this.item_variant = 'tv subscription';
        break;
      case 'PREPAID/MOBILE':
        this.item_category = 'Prepaid';
        this.item_category2 = 'Prepaid';
        this.item_category3 = 'Prepaid SIM';
        this.item_category4 = 'not selfcare';
        this.item_category5 = 'not selfcare';
        this.item_variant = 'prepaid mobile';
        break;
      case 'POSTPAID/INTERNET':
        this.item_category = 'Mobile abo';
        this.item_category2 = 'Mobile data';
        this.item_category3 = 'Internet on the Go';
        this.item_category4 = 'not selfcare';
        this.item_category5 = 'not selfcare';
        this.item_variant = 'postpaid mbb';
        break;
      case 'MBB/INTERNET':
        this.item_category = 'Mobile abo';
        this.item_category2 = 'Mobile data';
        this.item_category3 = 'Internet at Home';
        this.item_category4 = 'not selfcare';
        this.item_category5 = 'not selfcare';
        this.item_variant = 'postpaid mbb';
        break;
      case 'HFC/INTERNET':
        this.item_category = 'Fixline';
        this.item_category2 = 'Internet';
        this.item_category3 = 'Internet at Home';
        this.item_category4 = 'not selfcare';
        this.item_category5 = 'not selfcare';
        this.item_variant = 'postpaid cable';
        break;
      case 'FIBER/INTERNET':
        this.item_category = 'Fixline';
        this.item_category2 = 'Internet';
        this.item_category3 = 'Internet at Home';
        this.item_category4 = 'not selfcare';
        this.item_category5 = 'not selfcare';
        this.item_variant = 'postpaid fiber';
        break;
    }
  }
}
