import { isPlatformServer } from '@angular/common';
import { inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Client, createClient, filter, LinkResolverFunction, PrismicDocument } from '@prismicio/client';
import { BehaviorSubject } from 'rxjs';
import { shareReplay } from 'rxjs/operators';
import { lang } from 'interfaces';
import { LanguageService } from 'language';
import { TransferStateService } from 'utils';
import { PRISMIC_ENDPOINT } from './prismic.token';
import { AllDocumentTypes } from './types.generated.interface';

interface Languages {
  de: string;
  en: string;
  fr: string;
  it: string;
}

interface LanguageUIDS {
  [key: string]: Languages | object | string;
}

@Injectable({
  providedIn: 'root',
})
export class CmsService {
  private lang = inject(LanguageService);
  private transferState = inject(TransferStateService);
  private platformId = inject(PLATFORM_ID);
  private prismicEndpoint = inject(PRISMIC_ENDPOINT);
  languages: { [key in lang]: string } = { de: 'de-ch', en: 'en-gb', fr: 'fr-ch', it: 'it-ch' };
  homeUID = 'home';
  client: Client;
  private clientInitCompleteSubject = new BehaviorSubject<boolean>(false);
  public clientInitComplete$ = this.clientInitCompleteSubject.asObservable().pipe(shareReplay(1));
  alternateLanguageUIDs: LanguageUIDS = {};

  async init() {
    if (isPlatformServer(this.platformId)) {
      const { default: fetch } = await import('node-fetch');
      this.client = createClient<AllDocumentTypes>(this.prismicEndpoint, { fetch });
    } else {
      this.client = createClient<AllDocumentTypes>(this.prismicEndpoint);
    }
    this.clientInitCompleteSubject.next(true);
  }

  getLanguageFromLocale = (locale: string) => Object.entries(this.languages).find(([, value]) => value === locale)?.[0];

  async getByMultipleTypes(tag: string) {
    const locale = this.languages[this.lang.current];
    const documents = await this.client.getAllByTag(tag, { lang: locale });

    return documents;
  }

  /**
   * Gets a prismic document based on its type and unique identifier
   * @param type The type of document to search for
   * @param uid The unique identifier of the documnt
   */
  async getByUid(type: string, uid: string): Promise<PrismicDocument<Record<string, unknown>, string, string>> {
    const locale = this.languages[this.lang.current];
    const transferKey = locale + '_' + type + '_' + uid;
    const result = await this.transferState.withStateTransfer(transferKey, async () => {
      try {
        return await this.client.getByUID(type, uid, { lang: locale });
      } catch {
        return undefined;
      }
    });

    if (type == 'new_landing_page') {
      this.alternateLanguageUIDs[uid] = {};
      result?.alternate_languages.forEach(alternate => {
        const lang = this.getLanguageFromLocale(alternate.lang);
        (this.alternateLanguageUIDs[uid] as LanguageUIDS)[lang] = alternate.uid;
      });
    }
    return result;
  }

  /**
   * Gets a prismic document based on its ID
   * @param id The ID of the document to fetch (not the UID)
   * @returns A prismic document. If it doesn't exist it will throw an exception
   */
  async getById(id: string, lang = this.languages[this.lang.current]): Promise<PrismicDocument> {
    return await this.transferState.withStateTransfer(lang + '_' + id, () => this.client.getByID(id, { lang }));
  }

  async getAllByTag(tag: string): Promise<PrismicDocument[]> {
    const lang = this.languages[this.lang.current];
    const uniqueKey = `${lang}_${tag}`;

    return await this.transferState.withStateTransfer(uniqueKey, async () => {
      return await this.client.getAllByTag(tag, { lang });
    });
  }

  /**
   * Gets all documents from prismic for a certain type
   * @param type The type of document to get
   * @param enOnly True for document types that are not language specific
   * @param page Dont use, this is for internal recursive use
   * @param oldResults Dont use, this is for internal recursive use
   */
  async getByType(
    type: string,
    filters: string[] = [],
    enOnly = false,
    page = 1,
    fetchLinks: string[] = [],
    oldResults: PrismicDocument[] = []
  ): Promise<PrismicDocument<Record<string, unknown>, string, string>[]> {
    const lang = enOnly ? 'en' : this.languages[this.lang.current];
    const filtersKey = filters.length ? `_${filters.join('_')}` : '';
    const fetchLinksKey = fetchLinks.length ? `_${fetchLinks.join('_')}` : '';
    const key = `${lang}_${type}${filtersKey}${fetchLinksKey}`;

    return await this.transferState.withStateTransfer(key, () =>
      this.getByTypeRec(type, filters, lang, page, fetchLinks, oldResults)
    );
  }

  private async getByTypeRec(
    type: string,
    filters: string[],
    lang: string,
    page: number,
    fetchLinks: string[],
    oldResults: PrismicDocument[]
  ): Promise<PrismicDocument<Record<string, unknown>, string, string>[]> {
    const resp = await this.client.getByType(type, {
      lang,
      pageSize: 100,
      page,
      filters,
      fetchLinks,
    });
    if (resp.results) resp.results = oldResults.concat(resp.results);
    if (resp.total_pages > page) return this.getByTypeRec(type, filters, lang, page + 1, fetchLinks, resp.results);
    return resp.results;
  }

  /**
   * Gets all documents of a certain type that have a content relationship to a certain document
   * @param type The type of document to get
   * @param field The name of the field to get, for fields in groups, use group.field format
   * @param id The ID of the linked document (the one that is in the field)
   */
  async getByContentRelationship(
    type: string,
    field: string,
    id: string,
    options: { transferCache?: boolean } = { transferCache: true }
  ): Promise<PrismicDocument<Record<string, unknown>, string, string>[]> {
    const lang = this.languages[this.lang.current];
    if (options.transferCache) {
      return this.transferState.withStateTransfer([this.lang.current, type, field, id].join('_'), () =>
        this.getByContentRelationshipRec(type, field, id, lang)
      );
    }
    return this.getByContentRelationshipRec(type, field, id, lang);
  }

  async getPreviewRedirectURL(): Promise<string> {
    return await this.client.resolvePreviewURL({
      linkResolver: this.getLinkResolverFn(),
      defaultURL: '/',
    });
  }

  private async getByContentRelationshipRec(
    type: string,
    field: string,
    id: string,
    lang: string,
    page = 1,
    oldResults: PrismicDocument[] = []
  ): Promise<PrismicDocument<Record<string, unknown>, string, string>[]> {
    const resp = await this.client.get({
      filters: [filter.at('document.type', type), filter.at(`my.${type}.${field}`, id)],
      lang,
      pageSize: 100,
      page,
    });
    if (resp.results) resp.results = oldResults.concat(resp.results);
    if (resp.total_pages > page) return this.getByContentRelationshipRec(type, field, id, lang, page + 1, resp.results);
    return resp.results;
  }

  getLinkResolverFn(lang?: lang) {
    lang = lang ?? this.lang.current;
    const homeUID = this.homeUID;
    return <LinkResolverFunction<string>>((doc: { uid: string }) => {
      const path = doc.uid === homeUID ? '' : doc.uid;
      return `/${lang}/${path}`;
    });
  }

  /**
   * Gets a prismic document based on the value of a field
   */
  async getByFieldValue(
    type: string,
    field: string,
    value: string
  ): Promise<PrismicDocument<Record<string, unknown>, string, string>> {
    const locale = this.languages[this.lang.current];
    const transferKey = locale + '_' + type + '_' + field + '_' + value;
    const result = await this.transferState.withStateTransfer(transferKey, async () => {
      try {
        return await this.client.get({
          filters: [filter.at(`my.${type}.${field}`, value)],
          lang: locale,
        });
      } catch {
        return undefined;
      }
    });
    if (!result.results.length) return undefined;
    return result.results[0];
  }

  async fullTextSearch(location: string, value: string) {
    return this.client.get({
      filters: [filter.fulltext(location, value)],
      lang: this.languages[this.lang.current],
    });
  }
}
