import { ComponentType } from '@angular/cdk/portal';
import { inject, Injectable, QueryList, TemplateRef } from '@angular/core';
import { MatDialog, MatDialogConfig, MatDialogRef } from '@angular/material/dialog';
import { of } from 'rxjs';
import { map } from 'rxjs/operators';
import { Modal } from 'interfaces';
import { BrowserService } from 'utils';

@Injectable({
  providedIn: 'root',
})
export class ModalsService {
  private dialog = inject(MatDialog);
  private browser = inject(BrowserService);
  private pageExitModalId: string | undefined;
  private _modals: { [modalId: string]: TemplateRef<unknown> } = {};

  registerModals(modalsData: Modal[], modalsList: QueryList<TemplateRef<unknown>>) {
    modalsData.forEach((modal, index) => {
      this._modals[modal.id] = modalsList.get(index);

      if (modal.showOnPageExit) {
        this.executeFnForDevice(() => {
          this.pageExitModalId = modal.id;
        }, modal.showOnDevices);
      }
    });
  }

  unregisterModals() {
    this._modals = {};
    this.pageExitModalId = undefined;
  }

  openConfirmationDialogIfAny() {
    if (this.pageExitModalId) {
      return this.openModal(this.pageExitModalId)
        .afterClosed()
        .pipe(map(() => true));
    }

    return of(true);
  }

  openModal(id: string) {
    const modal = this._modals && this._modals[id];

    if (modal) {
      return this.dialog.open(this._modals[id]);
    }
    return null;
  }

  executeFnForDevice(fn: () => void, device: 'ALL' | 'MOBILE' | 'DESKTOP') {
    if (
      device === 'ALL' ||
      (device === 'MOBILE' && this.browser.matchMedia('(max-width: 1023px)')) ||
      (device === 'DESKTOP' && this.browser.matchMedia('(min-width: 1024px)'))
    ) {
      fn();
    }
  }

  openDialog(component: ComponentType<unknown>, options: MatDialogConfig = {}): MatDialogRef<unknown> {
    const dialogConfig: MatDialogConfig = {
      ...options,
      backdropClass: ['bg-[--basic-white-75]'],
    };

    return this.dialog.open(component, dialogConfig);
  }
}
