import { PrismicError } from "./errors/PrismicError.js";
import { isRepositoryName } from "./isRepositoryName.js";
const getRepositoryEndpoint = repositoryName => {
  if (isRepositoryName(repositoryName)) {
    return `https://${repositoryName}.cdn.prismic.io/api/v2`;
  } else {
    throw new PrismicError(`An invalid Prismic repository name was given: ${repositoryName}`, void 0, void 0);
  }
};
export { getRepositoryEndpoint };
